import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import Layout from './Layout';
import PrincipalHome from '../Home/PrincipalHome';
import PrincipalStudents from '../Students/PrincipalStudents';
import PrincipalTeachers from '../Teachers/PrincipalTeacher';
import PrincipalExam from '../Exam/PrincipalExam';
import PrincipalCalendar from '../Calendar/PrincipalCalendar';
import PrincipalFees from '../Fees/PrincipalFees';
import PrincipalMail from '../Mail/PrincipalMail';
import PrincipalNotice from '../Notice/PrincipalNotice'
import TeachersAttendance from '../Teachers/TeacherAttendance/TeachersAttendance';
import TeacherProfile from '../Teachers/TeacherProfile/TeacherProfile';
import StudentTimetable from '../Students/StudentTimetable/StudentTimetable';
import Profile from "../PrincipalProfile/Profile";
import StudentMarksTable from '../Students/StudentProfile/Academics/StudentMarksTable';
import TeacherMail from '../../teacher/Mail/TeacherMail';
import StudentProgress from '../Exam/StudentsProgress/StudentProgress';
function Routes() {
    return (
        <>
            <Router>
                <Route render={(props) => (
                    <Layout {...props}>
                        <Switch>
                            <Route exact path="/principal/home" component={PrincipalHome}></Route>
                            <Route exact path="/principal/student-profile" component={PrincipalStudents}></Route>
                            <Route exact path="/principal/student-timetable" component={StudentTimetable}></Route>
                            <Route exact path="/principal/student-marks" component={StudentMarksTable}></Route>
                            {<Route exact path="/principal/teachers" component={PrincipalTeachers}></Route>}
                            <Route exact path="/principal/teacher-attendance" component={TeachersAttendance}></Route>
                            <Route exact path="/principal/teacher-profile" component={TeacherProfile}></Route>
                            <Route exact path="/principal/exam" component={PrincipalExam}></Route>
                            <Route exact path="/principal/student-progress" component={StudentProgress}></Route>

                            <Route exact path="/principal/calendar" component={PrincipalCalendar}></Route>
                            <Route exact path="/principal/fees" component={PrincipalFees}></Route>
                            <Route exact path="/principal/mail" component={TeacherMail}></Route>
                            <Route exact path="/principal/notice" component={PrincipalNotice}></Route>
                            <Route exact path="/Principal/home/profile" component={Profile} />
                            <Redirect to="/principal/home" />
                        </Switch>
                    </Layout>
                )} />
            </Router>
        </>
    )
}

export default Routes
