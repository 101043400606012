import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Student from "./components/Student/Layout/StudentLayout";
import MenuState from "./context/Menu/MenuState";
import TeacherLayout from "./components/teacher/Layout/TeacherLayout";
import PrincipalLayout from "./components/Principal/Layout/PrincipalLayout";
import AccountsLayout from "./components/Accounts/Layout/AccountsLayout";
import AdminLayout from "./components/Admin/Layout/AdminLayout";
import Register from "./components/Register/Register";
import RegisterSchool from "./components/Register/RegisterSchool";
import RegisterPrincipal from "./components/Register/RegisterPrincipal";
import RegisterClassNotice from "./components/Register/RegisterClassNotice";
import RegisterSchoolNotice from "./components/Register/RegisterSchoolNotice";
import RegisterClassTest from "./components/Register/RegisterClassTest";
import RegisterHYExam from "./components/Register/RegisterHYExam";
import RegisterHYExamMark from "./components/Register/RegisterHYExamMarks";
import RegisterYExam from "./components/Register/RegisterYExam";
import RegisterYExamMark from "./components/Register/RegisterYExamMarks";
import RegisterClassTestMark from "./components/Register/RegisterClassTestMarks";
import RegisterSubjectTeachers from "./components/Register/RegisterSubjectTeachers";
import RegisterFeedbackStatus from "./components/Register/RegisterFeedbackStatus";
import RegisterAcademicFee from "./components/Register/RegisterAcademicFee";
import RegisterAcademicFeePaid from "./components/Register/RegisterAcademicFeePaid";
import RegisterAssignment from "./components/Register/RegisterAssignment";
import RegisterAssignmentStatus from "./components/Register/RegisterAssignmentStatus";
import RegisterSubject from "./components/Register/RegisterSubject";
import RegisterSyllabus from "./components/Register/RegisterSyllabus";
import RegisterNote from "./components/Register/RegisterNote";
import RegisterLink from "./components/Register/RegisterLink";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import "./App.css";

function App() {
  return (
    <>
      <ToastContainer />
      <MenuState>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/register/school" component={RegisterSchool} />
            <Route exact path="/register/principal" component={RegisterPrincipal} />
            <Route exact path="/register/classnotice" component={RegisterClassNotice} />
            <Route exact path="/register/classtest" component={RegisterClassTest} />
            <Route exact path="/register/classtestmark" component={RegisterClassTestMark} />
            <Route exact path="/register/hyexam" component={RegisterHYExam} />
            <Route exact path="/register/hyexammark" component={RegisterHYExamMark} />
            <Route exact path="/register/yexam" component={RegisterYExam} />
            <Route exact path="/register/yexammark" component={RegisterYExamMark} />
            <Route exact path="/register/schoolnotice" component={RegisterSchoolNotice} />
            <Route exact path="/register/subjectteacher" component={RegisterSubjectTeachers} />
            <Route exact path="/register/feedbackstatus" component={RegisterFeedbackStatus} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route exact path="/resetpassword" component={ResetPassword} />
            <Route exact path="/student/home" component={Student} />
            <Route exact path="/teacher" component={TeacherLayout} />
            <Route exact path="/admin" component={AdminLayout} />
            <Route exact path="/principal" component={PrincipalLayout} />
            <Route exact path="/accounts" component={AccountsLayout} />
            <Route exact path="/register/academicfee" component={RegisterAcademicFee} />
            <Route exact path="/register/academicfeepaid" component={RegisterAcademicFeePaid} />
            <Route exact path="/register/assignment" component={RegisterAssignment} />
            <Route exact path="/register/assignmentstatus" component={RegisterAssignmentStatus} />
            <Route exact path="/register/subject" component={RegisterSubject} />
            <Route exact path="/register/syllabus" component={RegisterSyllabus} />
            <Route exact path="/register/note" component={RegisterNote} />
            <Route exact path="/register/link" component={RegisterLink} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </MenuState>
    </>
  );
}

export default App;