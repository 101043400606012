import React, { useEffect, useState } from "react";
import "./PrincipalStudents.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStudents } from "../../../slices/student";  // Ensure this action fetches data as an array
import { getDropdownClasses } from "../../../slices/principal";
import useDebounce from "../../../Utils/debounce"; // If you want to use debouncing for search input

function PrincipalStudents() {
  const { user: currentUser } = useSelector((state) => state.user);
  const { allStudents = { students: [] } } = useSelector((state) => state.student); // Ensure allStudents has the correct structure
  const { classes } = useSelector((state) => state.principal);
  const dispatch = useDispatch();

  const [classFilter, setClassFilter] = useState("1A");
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // Debounce for search term input
  const [page, setPage] = useState(1);
  const [limit] = useState(5); // Set limit for pagination

  const currentYear = new Date().getFullYear(); // Get the current year for filtering

  // Function to apply the filters and fetch students
  const handleApplyFilter = () => {
    dispatch(
      fetchAllStudents({
        school_code: currentUser.schoolcode,
        class_code: classFilter,
        year: currentYear,
        searchTerm: debouncedSearchTerm, // Add search term for filtering
        page,
        limit,
      })
    );
  };

  useEffect(() => {
    // Fetch dropdown classes when the component mounts
    dispatch(getDropdownClasses({ schoolcode: currentUser.schoolcode }));
    handleApplyFilter(); // Fetch initial data
  }, [dispatch, currentUser.schoolcode]);

  useEffect(() => {
    // Apply filters when class or search term changes
    handleApplyFilter();
  }, [classFilter, debouncedSearchTerm, page]);

  // Handle page change for pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="prncplstdnt">
      <div>
        <p
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "21px",
            color: "#4D4D4D",
          }}
        >
          Home &gt; <b> <u>Student Profile</u></b>
        </p>
      </div>
      <h3 style={{ marginTop: "40px" }}>Student Profile</h3>
      <br />
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "20px",
            color: "#000000",
          }}
        >
          Filters:-
        </p>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <select
          style={{
            borderRadius: "5px",
            fontSize: "17px",
            padding: "6px 10px",
            color: "#414141",
          }}
          value={classFilter}
          onChange={(e) => setClassFilter(e.target.value)}
        >
          <option value="" hidden>Class</option>
          {classes?.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </select>
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <button className="applybtnprncpl" onClick={handleApplyFilter}>
            Apply
          </button>
          <button className="clearbtn" onClick={() => setClassFilter("")}>
            Clear
          </button>
        </div>
      </div>

      <div style={{ marginTop: "30px" }}>
        <TableContainer component={Paper} style={{ height: "370px" }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow
                style={{
                  background: "linear-gradient(180deg, #FDFDFD 0%, rgba(245, 245, 245, 0.92) 100%)",
                  border: "2px solid #A4A4A4",
                  borderRadius: "5px",
                }}
              >
                <TableCell style={{ fontWeight: "600", fontSize: "18px", color: "#545454" }}>
                  S no.
                </TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: "18px", color: "#545454" }}>
                  Academic Year
                </TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: "18px", color: "#545454" }}>
                  Class
                </TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: "18px", color: "#545454" }}>
                  Admission No.
                </TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: "18px", color: "#545454" }}>
                  Roll No.
                </TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: "18px", color: "#545454" }}>
                  Student Name
                </TableCell>
                <TableCell style={{ fontWeight: "600", fontSize: "18px", color: "#545454" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(allStudents.students) && allStudents.students.length > 0 ? (
                allStudents.students.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.academicyear}</TableCell>
                    <TableCell>{row.class}</TableCell>
                    <TableCell>{row.admissionno}</TableCell>
                    <TableCell>{row.rollno}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      <button type="button" className="entermarksbtn">
                        View Profile
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No students found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default PrincipalStudents;
